<template>
	<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" style="background-color: #FFF;">
        <router-link to="/">
            <div class="navbar-header pointer text-center">
                <img src='/assets/img/logo.png' class="img-fluid" alt="" style="width: 124px;">
            </div>
        </router-link>

        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" style="margin-bottom: 50px;">
                <li class="nav-item pl-0 pr-0" v-for="(data, idx) in menus" :key="idx" :class="isActive(data)">
                    <router-link :to="data.url" v-if="isAllowed(data.name)">
                        <i class="material-icons f-25">{{data.icon}}</i>
                        <span class="menu-title align-self-center font-400">{{data.name}}</span>
                        <span class="badge badge badge-danger badge-pill float-right" v-if="data.counter > 0">{{data.counter}}</span>
                    </router-link>
                    <ul class="menu-content">
                        <li v-for="(dt, i) in data.sub" :key="i">
                            <router-link :to="dt.url">
                                <i class="ml-2 fa fa-circle"></i>
                                <span class="menu-item">{{dt.name}}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            menus: [
                {
                    name: 'Dashboard',
                    url: '/',
                    icon: 'dashboard'
                },
                {
                    name: 'Transaksi',
                    url: '/transactions',
                    icon: 'receipt'
                },
                {
                    name: 'Promo',
                    url: '/promos',
                    icon: 'new_releases'
                },
                {
                    name: 'Sponsor',
                    url: '/sponsors',
                    icon: 'campaign'
                },
                {
                    name: 'Pengiriman',
                    url: '/shippings',
                    icon: 'local_shipping'
                },
                {
                    name: 'Kategori',
                    url: '/categories',
                    icon: 'category'
                },
                {
                    name: 'Produk',
                    url: '/products',
                    icon: 'set_meal'
                },
                {
                    name: 'Push Notification',
                    url: '/push_notifications',
                    icon: 'notifications'
                },
                {
                    name: 'User',
                    url: '/users',
                    icon: 'person'
                },
                {
                    name: 'Role',
                    url: '/roles',
                    icon: 'manage_accounts'
                },
                {
                    name: 'Tiket',
                    url: '/tickets',
                    icon: 'chat'
                },
                {
                    name: 'Setting',
                    url: '/settings',
                    icon: 'settings'
                },
            ],
            allowed_menu: []
        }
    },
    methods: {
        isActive(data){
            let _ = this
            if (data.url == '/' && _.$route.fullPath == '/') {
                return 'active';
            } else if (data.url != '/' && (data.url == _.$route.fullPath || _.$route.fullPath.indexOf(data.url) != -1)){
                return 'active';
            }
        },
        isAllowed(menu_name){
            return this.allowed_menu.map(x => x.name).indexOf(menu_name) != -1
        }        
    },
    mounted() {
        let _ = this

        let user = JSON.parse(localStorage.getItem('user'))
        _.allowed_menu = user.roles[0].permissions

        _.$root.$on('changeMenuCounter', (data) => {
            _.menus[data.id_menu].counter -= data.counter
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterToZero', (data) => {
            _.menus[data.id_menu].counter = 0
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterTo', (data) => {
            _.menus[data.id_menu].counter = data.counter
            _.$forceUpdate();
        })
    },
}
</script>